<template>
  <div>
    <el-button type="primary" v-print="'#printInfo'">打 印</el-button>
    <div id="printInfo">
      <div v-for="(showInfo, i) in showInfoList" :key="i" class="exhibit" >
        <div class="emptyNode">
        </div>
        <div class="infoNode">
          <div class="showERCodeNode">
            <QRcode
              width="125"
              height="125"
              :content="showInfo.qrUrl"
              :load="true"
            ></QRcode>
          </div>
          <div class="flexNode">
            <div class="showInfoNode">
              <div class="" style="margin: 0.1cm">
                <div class="infodes" >游玩日期：</div>
                <span style="width: 6rem;display: block">{{ showInfo.游玩日期 }}</span>
              </div>
              <div class="" style="margin: 0.1cm" v-if="personType != '1'">
                <div class="infodes">总价：</div>
                <span>￥{{ showInfo.价格 }}元</span>
              </div>
              <div class="" style="margin: 0.1cm">
                <div class="infodes">出票员：</div>
                <span>{{ userName }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="showInfoNode" v-if="personType != '1'">
          <div class="flexNode">
            <div class="infodes">门票号：</div>
            <span>{{ showInfo.订单号 }}</span>
          </div>
          <div class="flexNode">
            <div class="infodes">票数：</div>
            <span>{{ showInfo.数量 }}张</span>
          </div>
          <div class="flexNode">
            <div class="infodes">票名：</div>
            <span style="width: 9rem;display: block">{{ showInfo.title }}</span>
          </div>
          <div class="flexNode">
            <div class="infodes" >出票时间：</div>
            <span>{{ showInfo.购买时间 }}</span>
          </div>
        </div>
        <div class="showInfoNode image" v-if="personType == '1'">
          <div class="flexNode">
            <div class="infodes">门票号：</div>
            <span>{{ showInfo.订单号 }}</span>
          </div>
          <div class="flexNode">
            <div class="infodes">人数：</div>
            <span>{{ showInfo.数量 }}人</span>
          </div>
          <div class="flexNode">
            <div class="infodes" >团队名称：</div>
            <span style="width: 9rem;display: block">{{ showInfo.来源订单号 }}</span>
          </div>
          <div class="flexNode">
            <div class="infodes" >出票时间：</div>
            <span>{{ showInfo.购买时间 }}</span>
          </div>
          <div style="height: 1.5cm;width: 1.5cm;position: absolute; top: 0.3cm;left: 3cm;">
            <img src="../../assets/img/team_bg.png" style="height: 100%;width: 100%;" />
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import DateFormat from "@/utils/DateFormat.js";
export default {
  name: "goods",
  data() {
    return {
      showInfoList: [],
      date: this.$route.query.date,
      method: this.$route.query.method,
      personType: this.$route.query.personType,
      title: '',
      orderType: '',
      userName: window.sessionStorage.getItem("userName"),
    };
  },
  created() {
    let showInfoStr = this.$route.query.printInfo
    this.showInfoList = JSON.parse(showInfoStr)
    this.showInfoList.forEach(info => {
      info.购买时间 = new DateFormat(new Date(info.购买时间)).toString("yyyy-0m-0d 0h:0f:0s");
      let nameList = info.订单明细.map(o => o.名称.replace('瑶里历史文化名镇-','') + " * " + o.数量)

      if (info.订单明细.length===3&&(info.订单明细[0].泛商品ID==='34d4dd80-b265-11ed-ac51-99c64d372f2b'&&info.订单明细[1].泛商品ID==='34d4dd80-b265-11ed-ac51-99c64d372f2b'&&info.订单明细[2].泛商品ID==='34d4dd80-b265-11ed-ac51-99c64d372f2b')){
        info.数量=info.数量/3
      }
      info.title = nameList.join("，")
    })
    console.log(this.date, this.showInfoList)
  },
  methods: {
  },
};
</script>

<style scoped>
.exhibit {
  width: 10cm;
  height: 6cm;
  padding: 0.7cm 0.1cm 0.7cm 0cm;
}
.infoNode {
  width: 100%;
  display: flex;
}
.showInfoNode {
  /* width: 100%; */
  font-size: 0.2cm;
}
.showInfoNode .infodes {
  width: 4rem;
}
.showInfoNode .smallInfodes {
  font-size: 1mm;
}
.flexNode {
  display: flex;
  margin: 0.1cm;
}
.flexNode span {
  width: 5cm;
}
.image {
  position: relative;
}
</style>
